.unique-a {
  color: #ffffff; /* Set text color to black */
  text-decoration: none;
  
}
.font-xl{
  font-size: 16px;

}
.font-sm{
  font-size: 14px;

}

.unique-ul {
  background: #c99393; /* Set the background color to black */
  list-style: none;
  margin: 0;
  padding-left: 0;

}

.unique-li {
  color: #fff;
  background: #000; /* Set the background color to black */
  display: block;
  padding: 0.5rem;
  min-width: 10rem;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;

}

.unique-li a {
  color: #fff;
}

.unique-li:hover {
  background: #aaaaaa; /* Set the background color to black */
  cursor: pointer;
}

.unique-dropdown {
  background: #dec7c7; /* Set the background color of the dropdown to black */
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0.5rem;
  left: 0;
  z-index: 999;
  display: none;
}

.unique-li:hover > .unique-dropdown,
.unique-dropdown:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

.unique-dropdown .unique-li {
  clear: both;
  width: 100%;
}




  .marquee-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Adjust the height as needed */
    overflow: hidden;
    width: 240px;
    
  
  }
  
  .marquee {
    animation: marquee 20s linear infinite;
    padding-top: 3%;
  
  }
  .radius_2xl{
    border-radius: 20px;
  
  }
  
  @keyframes marquee{
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  
  .marq-top{
    animation: marq-top 20s linear infinite;
    padding-top: 3%;
  
  
  }
  
  @keyframes marq-top{
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  .three {
    display: grid;
    position: relative; /* Make the container a positioning context for pseudo-element */
    height: 100%;
    grid-template-columns: repeat(4, 1fr);
    overflow: hidden;
    gap: 2%;
  }
  
  .three::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694665995/LYMDATALABS/Alhub/login/Rectangle_3_sj4vhs.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
    opacity: 0.7; /* Adjust opacity as needed */
    z-index: 1; /* Ensure the pseudo-element is on top of other items */
  }
  
  
  .with-margin-top {
    margin-top: 30px;
  }
  
  .maketwo {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Create a 2-column grid */
    gap: 0px; /* Adjust the gap between columns as needed */
  }
  
  .blue {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red; /* Add this line to give it a red border */
    box-sizing: border-box; /* Ensure the border is included in the element's dimensions */
  }
  
  .ml-120{
    margin-left: -120px;
  }
  .w-full{
    width: 70%;
  }
  .flex{
    display: flex;
  }
  .flex-col{
    flex-direction: column;
  }
  .justify-center{
    justify-content: center;
  }
  .items-center{
    align-items: center;
  }
  .h-screen{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  @media (max-width: 991px) {
    .desktop-only {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .h-screen {
      display: none;
    }
  }

  .file-container {
    display: flex;
    align-items: center;
    margin: 8px;
  }
  
  .file-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  
  .file-name {
    font-size: 14px;
    margin: 0;
  }
  
  .remove-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .remove-button:hover {
    background-color: darkred;
  }
  